/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"

import "./styles.css"
import "./grid.css"
import "./bcash4you.css"
import "./base.css"
import "./myfontswebfontskit-bbox.css"

const Layout = ({ children }) => {
    return (
        <>
            <Helmet>
                <meta charset="utf-8" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0, viewport-fit=cover"
                />

                <title>BCASH4YOU: Watch4beauty affiliate program</title>

                <link
                    rel="apple-touch-icon"
                    sizes="180x180"
                    href="ico/apple-touch-icon.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="32x32"
                    href="ico/favicon-32x32.png"
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="16x16"
                    href="ico/favicon-16x16.png"
                />
                <link rel="manifest" href="ico/site.webmanifest" />
                <link
                    rel="mask-icon"
                    href="ico/safari-pinned-tab.svg"
                    color="#050505"
                />
                <meta name="apple-mobile-web-app-title" content="BCASH4YOU" />
                <meta name="application-name" content="BCASH4YOU" />
                <meta name="msapplication-TileColor" content="#050505" />
                <meta name="theme-color" content="#ffffff" />

                <meta name="RATING" content="RTA-5042-1996-1400-1577-RTA" />
            </Helmet>
            {children}
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
