import React, { useEffect, useContext } from 'react';
import { Link, navigate } from 'gatsby';
import Img from 'gatsby-image';
import { AppContext, App } from '../components/app';
import Layout from '../components/layout';
import Menu from '../components/menu';
import Footer from '../components/footer';

const FooterPage = ({ siteTitle }) => {
    const context = useContext(AppContext);
    const { state, dispatch } = context;

    const logout = e => {
        e.preventDefault();
        dispatch({ type: 'logout' });
        navigate('/');
    };

    return (
        <div className="footer">
            <div className="footer-fix">
                <div className="footer-panel">
                    <h4>BCASH4YOU</h4>
                    <ul>
                        <li>
                            <a
                                href="https://admin.ccbill.com/"
                                title="Statistics"
                            >
                                Statistics
                            </a>
                        </li>
                        <li>
                            <Link to="/tools-fhg" title="Promo tools">
                                Promo tools
                            </Link>
                        </li>
                        {(!state || !state.affiliateId) && (
                            <li>
                                <a
                                    href="https://nats.bcash4you.com/external.php?page=signup"
                                    title="Signup"
                                >
                                    Signup
                                </a>
                            </li>
                        )}
                        {(!state || !state.affiliateId) && (
                            <li>
                                <Link to="/login" title="Login">
                                    Login
                                </Link>
                            </li>
                        )}
                        {state && state.affiliateId && (
                            <li>
                                <a href="#" onClick={logout} title="Logout">
                                    Logout
                                </a>
                            </li>
                        )}
                        <li>
                            {state && state.affiliateId ? (
                                <Link to="/contact" title="Contact">
                                    Contact
                                </Link>
                            ) : (
                                <a
                                    href="mailto:support@bcash4you.com"
                                    title="Contact"
                                >
                                    Contact
                                </a>
                            )}
                        </li>
                    </ul>
                </div>

                <div className="footer-panel">
                    <h4>Follow</h4>
                    <ul>
                        <li>
                            <a
                                href="https://www.instagram.com/mark_watch4beauty/"
                                title="W4B on Instagram"
                            >
                                Instagram
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://twitter.com/W4B_MarK"
                                title="W4B on Twitter"
                            >
                                Twitter
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.facebook.com/Watch4beauty/"
                                title="W4B on Facebook"
                            >
                                Facebook
                            </a>
                        </li>
                    </ul>
                </div>

                <div className="footer-panel">
                    <h4>
                        <Link to="/legal" title="Legal">
                            Legal
                        </Link>
                    </h4>
                    <ul>
                        <li>
                            <Link
                                to="/legal#terms"
                                title="Terms and Conditions"
                            >
                                Terms
                            </Link>
                        </li>
                        <li>
                            <Link to="/legal#privacy" title="Privacy Policy">
                                Privacy Policy
                            </Link>
                        </li>
                        <li>
                            <Link to="/legal#cookies" title="Cookies Policy">
                                Cookies Policy
                            </Link>
                        </li>
                        <li>
                            <Link to="/legal#mail" title="Mail Policy">
                                Mail Policy
                            </Link>
                        </li>
                        <li>
                            <Link to="/legal#2257" title="2257">
                                2257
                            </Link>
                        </li>
                    </ul>
                </div>

                <div className="spacer spacer-40">&nbsp;</div>
                <div className="panel">
                    <a href="http://old.bcash4you.com" title="Old version">
                        <svg className="old-version">
                            <use
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                xlinkHref="#old-version"
                            ></use>
                        </svg>
                    </a>
                </div>
                <div className="spacer spacer-16">&nbsp;</div>
                <p className="micro padding-bottom-0">
                    Copyright &copy; 2005-2020 BCASH4YOU. Operated by
                    &#x4B;&nbsp;Production s.r.o.
                </p>
            </div>

            <div className="spacer spacer-60 show-for-tablet">&nbsp;</div>
        </div>
    );
};

export default FooterPage;
